import React from 'react';
import {Section, TopBg, HeadingLayout,Sectionheading,ContentLayout,
    ImageLayout,Description,DescriptionLayout,CtaLayout,CtaBtn} from './respondent.style';
import {Container} from '@bootstrap-styled/v4';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

const RespondentDetails = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                instituteJson{
                    Respondent{
                        SectionHeading
                        HeadingImg{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        Description
                        Description2
                        CtaBtnText
                        CtaBtnLink
                    }
                }
            }
    `);
        return (<>
        <TopBg/>
        <Section id="">
            <Container>
                <HeadingLayout>
                    <Sectionheading>
                        {JSONData.instituteJson.Respondent.SectionHeading}
                    </Sectionheading>
                </HeadingLayout>
                <ContentLayout>
                    <ImageLayout>
                        <GatsImg 
                            fluid={JSONData.instituteJson.Respondent.HeadingImg.childImageSharp.fluid} 
                            alt=""
                        />
                    </ImageLayout>
                    <DescriptionLayout>
                        <Description>
                            {JSONData.instituteJson.Respondent.Description}
                            <br/>
                            <br/>
                            {JSONData.instituteJson.Respondent.Description2}
                        </Description>
                        <CtaLayout>
                            <CtaBtn href={JSONData.instituteJson.Respondent.CtaBtnLink} target='_self'>
                                {JSONData.instituteJson.Respondent.CtaBtnText}
                            </CtaBtn>
                        </CtaLayout>
                    </DescriptionLayout>
                </ContentLayout>
            </Container>
        </Section>
        </>
    );
}

export default RespondentDetails;