import React from 'react';
import {Section, HeadingLayout, Sectionheading, ContentLayout, IconLayout, DescriptionLayout, Description, ItemLayout} from './respondentBenefits.style';
import {Container} from '@bootstrap-styled/v4';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

const RespondentBenefits = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                instituteJson{
                    RespondentBenefits{
                        
                        SectionHeading
                        Icon{
                            childImageSharp{
                                fixed(width: 25){
                                ...GatsbyImageSharpFixed
                                }
                            }
                        }
                        Items{
                            Description
                        }
                        
                    }
                }
            }
    `);
        return (
        <Section id="">
            <Container>
                <HeadingLayout>
                    <Sectionheading>
                        {JSONData.instituteJson.RespondentBenefits.SectionHeading}
                    </Sectionheading>
                </HeadingLayout>
                <ContentLayout>

                    {
                        JSONData.instituteJson.RespondentBenefits.Items.map((item,idx) => {
                        return <ItemLayout>
                            <IconLayout>
                                <GatsImg 
                                    fixed={JSONData.instituteJson.RespondentBenefits.Icon.childImageSharp.fixed} 
                                    alt=""
                                />
                            </IconLayout>
                            <DescriptionLayout>
                                <Description>
                                    {item.Description}
                                </Description>
                            </DescriptionLayout>

                        </ItemLayout>
                        })
                    }

                </ContentLayout>
            </Container>
        </Section>
    );
}

export default RespondentBenefits;