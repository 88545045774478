import styled from 'styled-components';
import {device} from '../../Institute/Common/device';
import {Commonpara,SectionHeading,Ctabtn} from '../../Institute/Common/common.style';
// import {RightArrow} from 'styled-icons/boxicons-regular/RightArrow';

export const Section = styled.section`
    padding:100px 0px 20px;
    background:#fafafa;

    @media ${device.tablet} {
        padding:80px 10px 20px;
    }
`;

export const TopBg = styled.div`
    height:90px;
    background-color: #30416B;
`;

export const HeadingLayout = styled.div`
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
`;

export const Sectionheading = styled(SectionHeading)`

`;

export const ContentLayout = styled.div`
    display:flex;
    flex-direction:row;
    /* align-items:center; */
    justify-content:space-between;

    @media ${device.laptop} {
        flex-direction:column;
    }
`

export const ImageLayout = styled.div`
    width:80%;

    @media ${device.tablet} {
        width:100%;
    }
    `;

export const DescriptionLayout = styled.div`
    width:75%;
    padding-left:50px;
    
    @media ${device.laptop} {
        text-align:justify;
        padding: 50px 0px 0px 0px;
        width:100%;
        /* width:65%; */
    }
    `;

export const Description = styled(Commonpara)`
    font-size:22px;
    line-height:1.4;
    font-weight:500;
    `;

export const CtaLayout = styled.div`
    display:flex;
    padding-top:30px;
    
    @media ${device.tablet} {
        align-items:center;
        justify-content:center;
    }
    `;
    
export const CtaBtn = styled(Ctabtn)`

    @media ${device.tablet} {
        font-size:20px;
        padding: 20px 20px;
    }
`;


