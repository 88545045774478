import React from 'react';
import {Section,ContentLayout,QuestionLayout,IconLayout,TitleLayout,Title,
DescriptionLayout,Description, InfoLayout} from './respondentQuestions.style';
import {Container} from '@bootstrap-styled/v4';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

const RespondentQuestions = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                instituteJson{
                    RespondentQuestions{
                        
                        Icon{
                            childImageSharp{
                                fixed(width: 40){
                                ...GatsbyImageSharpFixed
                                }
                            }
                        }
                        Title1
                        Description1
                        Title2
                        Description2
                        Title3
                        Description3
                        Title4
                        Description4
                        
                    }
                }
            }
    `);
        return (
        <Section id="">
            <Container>
                <ContentLayout>
                    <QuestionLayout>
                        <IconLayout>
                        <GatsImg 
                            fixed={JSONData.instituteJson.RespondentQuestions.Icon.childImageSharp.fixed} 
                            alt=""
                        />
                        </IconLayout>
                        <InfoLayout>
                            <TitleLayout>
                                <Title>
                                    {JSONData.instituteJson.RespondentQuestions.Title1}
                                </Title>
                            </TitleLayout>
                            <DescriptionLayout>
                                <Description>
                                    {JSONData.instituteJson.RespondentQuestions.Description1}
                                </Description>
                            </DescriptionLayout>
                        </InfoLayout>
                    </QuestionLayout>

                    <QuestionLayout>
                        <IconLayout>
                        <GatsImg 
                            fixed={JSONData.instituteJson.RespondentQuestions.Icon.childImageSharp.fixed} 
                            alt=""
                        />
                        </IconLayout>
                        <InfoLayout>
                            <TitleLayout>
                                <Title>
                                    {JSONData.instituteJson.RespondentQuestions.Title2}
                                </Title>
                            </TitleLayout>
                            <DescriptionLayout>
                                <Description>
                                    {JSONData.instituteJson.RespondentQuestions.Description2}
                                </Description>
                            </DescriptionLayout>
                        </InfoLayout>
                    </QuestionLayout>

                </ContentLayout>

                <ContentLayout>

                    <QuestionLayout>
                        <IconLayout>
                        <GatsImg 
                            fixed={JSONData.instituteJson.RespondentQuestions.Icon.childImageSharp.fixed} 
                            alt=""
                        />
                        </IconLayout>
                        <InfoLayout>
                            <TitleLayout>
                                <Title>
                                    {JSONData.instituteJson.RespondentQuestions.Title3}
                                </Title>
                            </TitleLayout>
                            <DescriptionLayout>
                                <Description>
                                    {JSONData.instituteJson.RespondentQuestions.Description3}
                                </Description>
                            </DescriptionLayout>
                        </InfoLayout>
                    </QuestionLayout>

                    <QuestionLayout>
                        <IconLayout>
                        <GatsImg 
                            fixed={JSONData.instituteJson.RespondentQuestions.Icon.childImageSharp.fixed} 
                            alt=""
                        />
                        </IconLayout>
                        <InfoLayout>
                            <TitleLayout>
                                <Title>
                                    {JSONData.instituteJson.RespondentQuestions.Title4}
                                </Title>
                            </TitleLayout>
                            <DescriptionLayout>
                                <Description>
                                    {JSONData.instituteJson.RespondentQuestions.Description4}
                                </Description>
                            </DescriptionLayout>
                        </InfoLayout>
                    </QuestionLayout>


                </ContentLayout>
            </Container>
        </Section>
    );
}

export default RespondentQuestions;