import styled from 'styled-components';
import {device} from '../../Institute/Common/device';
import {Commonpara,Commonh5} from '../../Institute/Common/common.style';
// import {RightArrow} from 'styled-icons/boxicons-regular/RightArrow';

export const Section = styled.section`
    padding:100px 0px 70px;
    background:#fafafa;

    @media ${device.tablet} {
        padding:80px 10px 50px;
    }
`;

export const ContentLayout = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;

    @media ${device.tablet} {
        flex-direction:column;
    }
`

export const QuestionLayout = styled.div`
    display:flex;
    flex-direction:row;
    padding:20px 30px 30px 0 ;
    width:60%;

    @media ${device.tablet} {
        width:100%;
    }
`;

export const IconLayout = styled.div`
    padding-right:15px;
`;

export const InfoLayout = styled.div`
    display:flex;
    flex-direction: column;
`;

export const TitleLayout = styled.div`
    /* padding:20px; */
    /* width:80%; */
`;

export const Title = styled(Commonh5)`
    font-size:22px;
    line-height:1.4;
    font-weight:500;
`;

export const DescriptionLayout = styled.div`
    /* width:75%; */
    /* margin-right: 20px; */

    @media ${device.laptop} {
        width:65%;
    }
    @media ${device.tablet} {
        width:100%;
    }
`;

export const Description = styled(Commonpara)`
    font-size:22px;
    line-height:1.4;
    font-weight:500;
`;