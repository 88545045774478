import styled from 'styled-components';
import {device} from '../../Institute/Common/device';
import {Commonpara, SectionHeading} from '../../Institute/Common/common.style';

export const Section = styled.section`
    padding:100px 0px 50px;

    @media ${device.tablet} {
        padding:80px 10px 40px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
`;

export const Sectionheading = styled(SectionHeading)`

`;

export const ContentLayout = styled.div`
    display:flex;
    flex-direction:column;
`

export const IconLayout = styled.div`
    padding-right:15px;
`;

export const ItemLayout = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
`;

export const DescriptionLayout = styled.div`
    /* width:75%; */
    /* margin-right: 20px; */

    @media ${device.laptop} {
        width:65%;
    }
    @media ${device.tablet} {
        width:100%;
    }
`;

export const Description = styled(Commonpara)`
    font-size:22px;
    line-height:1.4;
    font-weight:500;
`;